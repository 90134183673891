nav{
    height: 80px;
    display: grid;
    place-items: center;
    position: sticky;
    top:0;
    background-color: white;
    z-index: 100;
    box-shadow: 0px 4px 20px var(--color-dark-ex);
    /* box-shadow: 0 0 1rem rgb(0,0,0,0.4);
    border: 1px solid var(--color-dark-ex); */
  }
  .logo {
    width: 30%;
    height: 55px; /* Ensure the logo fills the height of the navbar */
    background-size: contain; /* Adjust the background size as needed */
    background-repeat: no-repeat; /* Adjust the background repeat behavior as needed */
  }
  .nav-container{
    display: flex;
    height: 100px;;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .nav-links{
    display: flex;
    align-items: center;
    gap: 30px;
  }
  .nav-links a{
    color: var(--color-dark);
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .nav-links .active{
    color:var(--dark-shade-4);
  }
  
  .nav-right{
    display: flex;
    align-items: center;
    gap: 30px;
  }
  .nav-right a{
    color: var(--color-dark);
    font-size: 20px;
  }
  .menu-button{
    display: none;
  }
  
  @media(max-width:1024px){
    nav{
      height: 90px;
    }
    .nav-container{
      position: relative;
      height: 100%;
    }
    
    .nav-links{
      flex-direction: column;
      gap: 0;
      width: 20rem;
      transition: var(--transition);
      top:90px;
      right: 0;
      position: fixed;
      perspective: 400px ;
    }
    .nav-links li{
      height: 100%;
      width: 100%;
      animation: navAnimation 600ms forwards ease;
      transform: rotateY(-90deg);
    }
    
    
    .nav-links a{
      display: inline-block;
      background-color: white;
      height:80px;
      width: 100%;
      display: flex;
      padding-left: 40px;
      align-items: center;
      overflow: hidden;
      box-shadow: -1rem 5rem 5rem rgba(0,0,0,0.4);
    }
    .menu-button{
      display: inline-block;
      font-size: 18px;
    }
    .navshow{
      display: flex;
    }
    .navhide{
      display: none;
    }
    
  }
  
  @media(max-width:550px){
    .nav-links{
        width:18rem;
    }
  }
  
  /* .....navlink animation...... */
  @keyframes navAnimation {
    to{
      transform: rotateY(0);
      opacity: 1;
    }
  }
  .nav-links li:nth-child(2){
    animation-delay: 50ms;
  }
  .nav-links li:nth-child(3){
    animation-delay: 100ms;
  }
  .nav-links li:nth-child(4){
    animation-delay: 150ms;
  }
  .nav-links li:nth-child(5){
    animation-delay: 200ms;
  }
  
  
  
  
  
  
  
  
  
  
  .nav-links a{
    text-decoration: none !important;
  }
  .navbar{
      display: flex;
      justify-content:space-between;
      padding: 12px;
      box-shadow: -5px -6px 20px 0px var(--shade4);
  }
  .nav-login{
      align-items: center;
      padding:8px;
      justify-content:space-between;
  }
  .nav-login button{
      background-color: var(--shade1);
      margin:15px;
      
  }
  .nav-login a {
      color: #000000;
      text-decoration: none;
  }
  .nav-container ul{
    list-style: none;
  }
 

  
    .arrow-icon {
      margin-left: 5px;
    }
  
    .dropdown-menu {
      position: absolute;
      top: calc(100% + 5px); /* Adjust this value to your preference */
      right: 0;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
      z-index: 1000; /* Ensure it's above other elements */
    }
  
    .dropdown-menu a {
      display: block;
      padding: 8px 12px;
      text-decoration: none;
      color: #333;
    }
  
    .dropdown-menu a:hover {
      background-color: #f4f4f4;
    }
  
