.product-card {
    margin: 20px;
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(125, 37, 236, 0.15); /* Softer shadow with base color */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background:linear-gradient(135deg, var(--light-tint-3), #f59ad1); /* Gradient background */
    padding: 10px;
  }
  
  .product-card .image-wrapper {
    position: relative;
    width: 600px;
    height: 400px;
    margin: 0 auto;
    border-radius: 15px;
    overflow: hidden;
  }
  @media(max-width:850px){
    .product-card .image-wrapper{
      width:450px;
      height:300px;
    }
  }
  @media(max-width:520px){
    .product-card .image-wrapper{
      width:380px;
      height:260px;
    }
  }
  
  .product-card .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover .card-image {
    transform: scale(1.05); /* Zoom effect on hover */
  }
  
  .product-card .card-text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
    background: linear-gradient(180deg, rgb(125 37 236 / 12%), rgba(61, 15, 121, 0.8));
    border-radius: 15px;
    width: 100%;
    height: 100%;
    color: var(--light-color); /* Lightest purple for text */
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .product-card .card-title {
    font-size: 1.8em;
    margin-bottom: 10px;
  }
  
  .product-card .card-text {
    font-size: 1.1em;
    margin-bottom: 15px;
  }
  
  .product-card .card-updated {
    font-size: 0.9em;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(125, 37, 236, 0.25); /* Deeper shadow on hover */
  }
  .product-card:hover .card-image {
    transform: scale(1.1) rotate(2deg); /* Zoom and slight rotation */
    filter: blur(2px); /* Subtle blur effect */
  }
  .card-description{
    text-align: left;
    font-size: 10px;
  }
  @media(max-width:480px){
    .product-card .card-title{
      font-size: 1.5em;
    }
    .card-title .card-text{
      font-size: 0.8em;
    }
  }

  @media(max-width: 450px){
    .product-card .image-wrapper{
      width:320px;
      height:220px;
    }
  }
