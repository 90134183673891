/* FAQ Section Styling */
.faq-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    color: var(--text-color); /* Dark text for contrast */
    min-height: 100vh; /* Full viewport height to center vertically */
    margin-bottom: 40px;
  }
  
  .section-title {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: var(--base-color); /* Base color for title */
  }
  
  .accordion {
    width: 80%;
    max-width: 800px; /* Optional: Max width for the accordion */
    margin: 0 auto; /* Center the accordion horizontally */
  }
  
  .faq-item .accordion-header {
    font-weight: bold;
    font-size: 1.1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 1.25rem;
    cursor: pointer;
    color: var(--base-color); /* Base color for text */
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  .faq-item .accordion-header button {
    background: none;
    border: none;
    color: var(--color-black-ex);
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }
  
  .faq-item .accordion-header button::after {
    display: none;
  }
  .faq-item .accordion-header button{
    padding: 10px;
  }
  .faq-item .accordion-header button:hover,
  .faq-item .accordion-header button:focus {
    border: none;
  }
  
  .faq-toggle-icon {
    margin-left: 10px;
    font-size: 1.5em;
  }
  
  .faq-item .accordion-body {
    background-color: var(--lightest-color); /* Lightest color for body */
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(125, 37, 236, 0.1); /* Light shadow */
  }
  .accordion-button:focus{
    box-shadow: none;
  }
  .accordion .accordion-item {
    border: none;
    border-bottom: 1px solid #a1a1a1b4; /* Thin black line after each question */
  }
  
  .accordion .accordion-item:last-child {
    border-bottom: none; /* No line after the last item */
  }
  
  .accordion-item + .accordion-item {
    margin-top: 20px; /* Space between items */
  }
  
 
  